.featured {
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    z-index: 1;
    margin: 50px auto;
  }
  
  .featuredItem {
    position: relative;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
    flex: 1;
    margin: 10px; /* Add margin to prevent overlap */
  }
  
  .featuredImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .featuredTitles {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  @media (max-width: 768px) {
    .featured {
      flex-direction: column;
      align-items: center;
      margin-top: 210px; /* Increased top margin */
    }
  
    .featuredItem {
      width: 100%;
      max-width: 90%;
      margin: 20px 0; /* Ensure margin around each item */
    }
  }