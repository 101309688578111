.listContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .listWrapper {
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .listSearch {
    flex: 1;
    background-color: #febb02;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .lsTitle {
    font-size: 20px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .lsItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .lsItem > label {
    font-size: 12px;
  }
  
  .lsItem > input,
  .lsItem > span,
  .lsItem > select {
    height: 40px;
    border: none;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
  }
  
  .lsOptions {
    padding: 10px;
  }
  
  .lsOptionItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #555;
    font-size: 12px;
  }
  
  .lsOptionInput {
    width: 60px;
  }
  
  .listSearch > button {
    padding: 10px;
    background-color: #0071c2;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    align-self: flex-end;
  }
  
  .listResult {
    flex: 3;
    margin-top: 20px;
  }
  
  /* Media query for mobile */
  @media (max-width: 768px) {
    .listWrapper {
      flex-direction: column;
    }
  
    .listResult {
      margin-top: 20px;
    }
  }
  