.pList {
  width: 100%;
  max-width: 1400px; /* Increase the max width for a wider layout */
  display: flex;
  justify-content: center;
  gap: 30px; /* Increase the gap between cards */
  flex-wrap: wrap; /* Ensures items wrap on smaller screens */
  margin: 20px auto; /* Centers the container on the screen */
  padding: 0 20px; /* Adds padding around the container */
}

.pListItem {
  flex: 1 1 350px; /* Increase the width of each item */
  max-width: 350px; /* Increase the max width of each item */
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds smooth transition effects */
  margin: 10px; /* Add margin to prevent overlap */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial shadow effect */
  background-color: #fff; /* Add background color */
}

.pListItem:hover {
  transform: scale(1.05); /* Slightly enlarges the item on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds a stronger shadow effect on hover */
}

.pListImg {
  width: 100%;
  height: 200px; /* Increase height to ensure images are more visible */
  object-fit: cover;
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.pListItem:hover .pListImg {
  opacity: 0.9; /* Slightly decrease opacity on hover */
}

.pListTitles {
  padding: 20px; /* Increase padding around the text */
  background-color: #f9f9f9; /* Light background for text area */
  text-align: center; /* Centers text */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.pListItem:hover .pListTitles {
  background-color: #e9e9e9; /* Slightly darker background on hover */
}

.pListTitles > h1,
.pListTitles > h2 {
  color: #333; /* Dark color for better contrast */
  margin: 10px 0; /* Adds space between titles */
  transition: text-decoration 0.3s, color 0.3s; /* Smooth transition for text decoration and color */
  text-decoration: none; /* Remove the default underline */
}

.pListTitles > h1:hover,
.pListTitles > h2:hover {
  color: #a27707; /* Change text color on hover */
}

.pListLink {
  text-decoration: none; /* Remove blue underlining */
}

.pListLink:active .pListTitles > h1,
.pListLink:active .pListTitles > h2 {
  text-decoration: underline; /* Underlines text when link is clicked */
}

@media (max-width: 768px) {
  .pList {
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Ensure gap around each item */
  }

  .pListItem {
    width: 100%;
    max-width: 90%;
    margin: 20px 0; /* Ensure margin around each item */
  }

  .pListImg {
    height: 150px; /* Adjust height for smaller screens */
  }

  .pListTitles > h1 {
    font-size: 18px; /* Adjust font size for mobile */
  }

  .pListTitles > h2 {
    font-size: 14px; /* Adjust font size for mobile */
  }
}
