.classContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;
}

.classWrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding-top: 50px; /* Add space for the button */
}

.bookNow {
  position: absolute;
  top: 10px;
  right: 20px;
  border: none;
  padding: 10px 20px;
  background-color: #a27707; /* Gold color */
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1; /* Ensure button is above other content */
}

.classTitle {
  font-size: 24px;
  text-align: left;
  margin-bottom: 10px;
}

.classAddress {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.classImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.classImgWrapper {
  width: 100%;
  flex: 1 1 calc(33% - 10px);
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px; /* Set a max height */
  overflow: hidden; /* Hide overflow */
}

.classImg {
  width: 100%;
  height: 500px; /* Fixed height */
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
}

.classDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.classDetailsTexts {
  flex: 3;
  text-align: left;
}

.classOneLiner {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.classDesc {
  font-size: 14px;
  margin-top: 20px;
  line-height: 1.5;
}

.classInfo {
  margin-top: 20px;
}

.classInfo p {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.classDetailsPrice {
  flex: 1;
  background-color: #ebf3ff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 5px;
  align-items: center;
}

.classDetailsPrice > h2 {
  font-size: 24px;
  color: #555;
  text-align: center;
}

.classDetailsPrice > button {
  border: none;
  padding: 10px 20px;
  background-color: #a27707; /* Gold color */
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.classInfo .fa-icon {
  color: #a27707;
}

.slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderImg {
  width: 100%;
  height: 500px; /* Match the height of .classImg */
  max-width: 90vw; /* Prevent image from overflowing on smaller screens */
  object-fit: contain; /* Ensure the entire image is visible */
  border-radius: 5px;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: lightgray;
  cursor: pointer;
}

.arrow {
  margin: 20px;
  font-size: 50px;
  color: lightgray;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .classWrapper {
    padding: 0 10px;
    padding-top: 60px; /* Increase space for button */
  }

  .classImgWrapper {
    flex: 1 1 calc(50% - 10px);
  }

  .bookNow {
    top: 10px;
    right: 10px;
    padding: 8px 16px; /* Slightly smaller padding */
    font-size: 14px; /* Smaller font size */
  }

  .classDetails {
    flex-direction: column;
  }

  .classDetailsPrice {
    align-items: flex-start;
    text-align: left;
  }

  .classImg, .sliderImg {
    height: 500px; /* Maintain the same height on tablets */
  }
}

@media (max-width: 480px) {
  .classImgWrapper {
    flex: 1 1 100%;
  }

  .classTitle {
    font-size: 20px;
  }

  .classDesc {
    font-size: 12px;
  }

  .classAddress {
    font-size: 12px;
  }

  .classDetailsPrice > h2 {
    font-size: 20px;
  }

  .bookNow {
    top: 5px;
    right: 5px;
    padding: 6px 12px; /* Even smaller padding */
    font-size: 12px; /* Smaller font size */
  }

  .sliderImg {
    width: 90%;
  }

  .classImg, .sliderImg {
    height: 500px; /* Maintain the same height on mobile */
  }
}