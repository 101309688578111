.searchItem {
    border: 1px solid lightgray;
    padding: 10.5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column; /* Change to column for mobile */
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .siImg {
    width: 100%; /* Make the image full width */
    height: auto;
    border-radius: 5px; /* Add a bit of rounding for a nicer look */
    object-fit: cover;
  }
  
  .siDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 2;
  }
  
  .siTitle {
    font-size: 20px;
    color: #0071c2;
  }
  
  .siSubtitle {
    font-size: 12px;
    font-weight: bold;
  }
  
  .siFeatures {
    font-size: 12px;
  }
  
  .siCancelOp {
    font-size: 12px;
    color: #008009;
    font-weight: bold;
  }
  
  .siCancelOpSubtitle {
    font-size: 12px;
    color: #008009;
  }
  
  .siDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .siDetailTexts {
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .siPrice {
    font-size: 24px;
  }
  
  .siTaxOp {
    font-size: 12px;
    color: gray;
  }
  
  .siCheckButton {
    background-color: #A27707; /* Change button color to gold */
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  @media (min-width: 768px) {
    .searchItem {
      flex-direction: row; /* Revert to row on larger screens */
    }
    
    .siImg {
      width: 200px;
      height: 200px;
    }
  }
  